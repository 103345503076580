<template>
  <main class="content-page">
    <section class="submenu">
      <span class="highlight"></span>
      <h1 class="-h6">Agents</h1>

      <section class="actions">
        <div class="btn-container">
          <Button
            :state="'secondary'"
            :title="'Create New Agent'"
            @click="setModal('create')"
          />
        </div>
        <div class="btn-container">
          <Button
            :state="'primary'"
            :title="'Invite Member'"
            @click="setModal('invite')"
          />
        </div>
        <div class="search-container">
          <SearchBar />
        </div>
      </section>
    </section>

    <div class="listing"><AgentsListing @setModal="setModal" /></div>
  </main>
</template>

<script>
import SearchBar from "../../../components/utils/BaseSearchBar.vue";
import Button from "../../../components/utils/BaseButton.vue";
import AgentsListing from "../../../components/tables/mscope/AgentsListing.vue";

export default {
  props: ["getRouteName"],
  components: { SearchBar, Button, AgentsListing },
  methods: {
    setModal(cmd, item) {
      const route = this.getRouteName();
      const scopeModal = {
        cmd: cmd,
        scope: route,
        item: item,
      };
      this.$store.dispatch("scopeModal", scopeModal);
    },
  },
};
</script>

<style scoped>
@import url("../../../assets/scopeListings.css");
</style>